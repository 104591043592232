//for global tooltip
// .customtooltip {
//     top: 0;
//     pointer-events: none;
//     padding: 5px 10px !important;
//     text-wrap: nowrap;
//     position: absolute;
//     color: white !important;
//     background-color: black !important;
//     border-radius: 5px !important;
//     width: fit-content;
//     font-size: font("f-Small");
//     z-index: auto;
// }

//for global dragdrop css (css names should not be used)
.vdm-position-absolute{
    position: absolute;
    top: 0;
}

.vdm-position-relative{
    position: relative;
}

#displayDropHere {
    opacity: 0;
    font-weight: bold;
    z-index: -1;
    font-size: calc(14px + 16px);
    transform: scale(0.6);
    overflow: hidden;
}

.displayDropHere {
    overflow: hidden !important;

    :not(#displayDropHere) {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        z-index: 0;
    }

    #displayDropHere {
        z-index: 1;
        opacity: 1;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        transform: scale(1);
        transition: opacity 0.5s;
        background-color: white;
    }
}

.hideDropHere {
    :not(#displayDropHere) {
        opacity: 1;
        visibility: visible;
        pointer-events: none;
        transition: height 0.5s;
        transition: opacity 0.5s;
    }
}

.drag-drop-pointer-event-all{
    pointer-events: all !important;
}